
// General
import Vue from "vue";

// Components
import FileUploader from "@/components/form-components/FileUploader.vue";

// Configs
import rules from "@/services/helpers/validation-rules";
import documentsModel from "../config/documents-model.json";
import Comments from "@/modules/orders/components/Comments.vue";
import CommentsPreview from "@/modules/orders/components/CommentsPreview.vue";

export default Vue.extend({
  name: "Documents",

  components: {
    CommentsPreview,
    Comments,
    FileUploader,
  },

  props: {
    orderType: {
      type: [String, Number],
      required: true,
    },
    area: {
      type: [String, Number],
      required: false,
    },
    extraRules: {
      type: Array,
      required: false,
    },
    documents: {
      type: Array,
      required: false,
    },
    preview: {
      type: Boolean,
      required: false,
      default: false,
    },
    commentsReadonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    showComments: {
      type: Boolean,
      required: false,
      default: false,
    },
    comments: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    errorMessages: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    acceptMain: {
      required: false,
      type: String,
      default: "image/jpeg,image/png,application/pdf,.xls,.xlsx",
    },
    acceptOthers: {
      required: false,
      type: String,
      default: "image/jpeg,image/png,application/pdf,.xls,.xlsx",
    },
    clientType: {
      required: false,
      type: String,
    },
  },

  data: () => ({
    rules,
    documentsModel,
    docsModel: {} as any,
    obligatoryDocuments: [] as Array<any>,
    extraDocuments: {
      other: {
        extra: [],
      },
      agro: {
        constatation: [],
        authority: [],
      },
    } as any,
    extraDocs: [] as Array<any>,
    maxDocumentCounter: 20,
  }),

  watch: {
    area: {
      handler() {
        if (this.area) {
          const documentsModel = (this.documentsModel as any)[this.orderType];
          for (const document of documentsModel.extra[this.area]) {
            this.extraDocuments[this.area][document] = [
              {
                file: null,
                file_type: document,
              },
            ];
          }
        }
      },
    },
    orderType: {
      immediate: true,
      deep: true,
      handler() {
        this.docsModel = (this.documentsModel as any)[this.orderType];
      },
    },
    comments: {
      deep: true,
      handler() {
        this.$forceUpdate();
      },
    },
  },

  computed: {
    showAddButton() {
      if (this.extraDocuments[this.area].extra) {
        return (
          !this.preview && this.extraDocuments[this.area].extra.length < 20
        );
      }
      return !this.preview;
    },
    isJuridical() {
      return this.clientType === "juridical";
    },
    isPhysical() {
      return this.clientType === "physical";
    },
    isAta() {
      return this.orderType === 1;
    },
  },

  mounted() {
    this.generateFields();
  },

  methods: {
    async modifyDocuments() {
      this.extraDocs = Object.values(this.extraDocuments[this.area]).map(
        (item: any) => {
          return item
            .filter((item: any) => item.file && item.file.name)
            .map((item: any) => {
              return {
                ...item,
                file_type: item.file_type,
              };
            });
        }
      );
      this.$emit("update", [
        ...this.obligatoryDocuments,
        ...this.extraDocs.flat(),
      ]);
    },
    addFile(fileType: string) {
      this.extraDocuments[this.area][fileType].push({
        file: null,
        file_type: `${fileType}.${
          this.extraDocuments[this.area][fileType].length
        }`,
      });
      this.$forceUpdate();
    },
    deleteFile(fileType: string, index: number) {
      this.extraDocuments[this.area][fileType].splice(index, 1);
      this.$forceUpdate();
    },
    generateFields() {
      const documentsModel = (this.documentsModel as any)[this.orderType];
      this.setUpExtraDocuments();

      if (!this.documents && documentsModel.obligatory) {
        for (const document of documentsModel.obligatory) {
          this.obligatoryDocuments.push({
            file: null,
            file_type: document,
          });
        }
      } else {
        if (documentsModel.obligatory) {
          this.obligatoryDocuments = [
            ...this.documents?.filter((item: any) =>
              documentsModel.obligatory.includes(item.file_type)
            ),
          ];
        }
        for (const document of documentsModel.extra[this.area]) {
          this.extraDocuments[this.area][document] = [
            ...this.documents.filter((item: any) => {
              return item.file_type.startsWith(document);
            }),
          ];
        }
      }
      this.$forceUpdate();
    },
    setUpExtraDocuments() {
      if (!this.area) return;
      for (const documentType of this.docsModel.extra[this.area]) {
        this.extraDocuments[this.area][documentType] = [];
      }
    },
    async updateComments(event: any) {
      this.$emit("getComments", event);
    },
    getErrorMessage(fileType: string): Array<any> {
      const items = [...this.obligatoryDocuments, ...this.extraDocs.flat()];
      const index = items.findIndex((item: any) => item.file_type === fileType);
      if (index !== -1) {
        return this.errorMessages[`documents.${index}.file`];
      }
      return [];
    },
    getDocsLabel(key: string, type: string): any {
      return this.$t(`orders.form.fields.${type}.${key}`);
    },
    isPersonPhysical(doc: any) {
      return (
        this.isPhysical && doc.file_type === "register_enterprises_extract"
      );
    },
  },
});
