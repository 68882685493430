import { render, staticRenderFns } from "./ProductsPicker.vue?vue&type=template&id=2e74d425&scoped=true&"
import script from "./ProductsPicker.vue?vue&type=script&lang=ts&"
export * from "./ProductsPicker.vue?vue&type=script&lang=ts&"
import style0 from "./ProductsPicker.vue?vue&type=style&index=0&id=2e74d425&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e74d425",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCol,VIcon,VListItemContent,VListItemTitle,VRow,VTextField})
